import Vue from 'vue'
import App from './App.vue'
import router from './router'

import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import "./styles/index.scss";
import "vue2-animate/src/sass/vue2-animate.scss";
import "animate.css";



Vue.config.productionTip = false
Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
